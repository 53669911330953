<template>
    <div class="edit-keywords">
        <ul class="categories">
            <li class="category" v-for="category in categories" :key="category.id">
                <div class="key" v-html="`# ${category.title}`" />
                <div class="keywords" @click="addKeyword(category)">
                    <div class="keyword" v-for="keyword in category.keywords" :key="keyword.id">{{ keyword.name }}</div>
                    <div v-if="category.keywords.length === 0" class="keyword add-keyword">
                        <i class="material-icons">add</i>
                        <span v-html="$translate('ADD_ITEM')" />
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'EditKeywords',
    data: () => ({
        keywordChanged: false,
        categories: [],
    }),
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        keywords() {
            return this.me.keywords || []
        },
    },
    watch: {
        me: {
            handler() {
                this.init()
            },
            deep: true,
        },
        keywords: {
            handler(newVal, oldVal) {
                this.keywordChanged = !!oldVal
            },
            deep: true,
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        async init() {
            this.categories = this.$store.getters.keywordCategories.map(c => {
                return { id: c.id, title: c.title, keywords: [], description: c.description }
            })

            this.me.keywords.forEach(keyword => {
                this.categories.forEach(category => {
                    if (category.id === keyword.keyword_category_id) {
                        category.keywords.push({ id: keyword.id, name: keyword.name })
                    }
                })
            })
        },
        addKeyword(category) {
            this.$stackRouter.push({
                name: 'SearchKeywordsPage',
                props: { category },
            })
        },
    },
    beforeDestroy() {
        if (this.keywordChanged) this.$toast.success('SAVED')
    },
}
</script>

<style lang="scss" scoped>
.edit-keywords {
    padding: 32px 8px 32px 16px;

    .title {
        margin-bottom: 24px;
        color: black;
        font-size: 18px;
        font-weight: 500;

        @include f-medium;
    }

    .key {
        margin-bottom: 12px;
        font-size: 12px;
        color: $grey-06;

        @include f-medium;
    }

    .category {
        margin-bottom: 24px;
    }

    .keywords {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .keyword {
        margin-right: 8px;
        margin-bottom: 8px;
        padding: 0 12px;
        border-radius: 8px;
        height: 32px;
        font-size: 14px;
        border: solid 1px $purple-primary;
        color: $purple-primary;

        @include f-medium;
        @include center;
    }

    .add-keyword {
        color: white;
        background-color: $purple-primary;
        border: 0;

        .material-icons {
            margin-right: 2px;
            color: white;
            font-size: 12px;
        }
    }
}
</style>
