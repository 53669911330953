<template>
    <ul class="edit-profile-tabs">
        <li class="tab" v-for="tab in tabs" :key="tab" @click="onClickTab(tab)">
            <span class="label" v-html="$translate(`EDIT_PROFILE_TAB_${tab}`)" :class="{ active: isSelected(tab) }" />
            <div v-show="isSelected(tab)" class="indicator" />
        </li>
    </ul>
</template>

<script>
export default {
    name: 'EditProfileTabs',
    props: {
        value: String,
        viewOnlyPhoto: Boolean,
    },
    data: () => ({
        tabs: [],
        selected: null,
    }),
    created() {
        this.initTabs()
    },
    mounted() {
        this.selected = this.value
    },
    methods: {
        initTabs() {
            if (this.viewOnlyPhoto) {
                this.tabs = ['PHOTO']
            } else {
                this.tabs = ['PHOTO', 'PROFILE', 'INTEREST_TAG']
            }
        },
        isSelected(tab) {
            return this.selected === tab
        },
        onClickTab(tab) {
            if (tab === 'PROFILE') {
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'ProfileUpdatePage_Click_ProfileMenu',
                    },
                })
            }
            this.selected = tab
            this.$emit('input', tab)
        },
    },
}
</script>

<style lang="scss" scoped>
.edit-profile-tabs {
    display: flex;
    border-bottom: solid 1px $grey-02;

    .tab {
        position: relative;
        width: calc(100% / 3);
        height: 48px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .label {
        font-size: 16px;
        color: $grey-06;

        &.active {
            color: $grey-09;

            @include f-medium;
        }
    }

    .indicator {
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: $grey-09;
        bottom: 0;
    }
}
</style>
