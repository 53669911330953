<template>
    <div v-if="me && profile" class="edit-profile">
        <header class="header-bar">
            <i class="material-icons" @click="back">chevron_left</i>
            <transition name="fade">
                <h2 v-show="showHeaderTitle" class="header-title" v-html="$translate('EDIT_PROFILE')" />
            </transition>
        </header>
        <main class="main" @scroll="onScroll" ref="main">
            <div class="page-header">
                <div class="title" v-html="$translate('EDIT_PROFILE')" />
            </div>
            <EditProfileTabs v-model="selectedTab" :viewOnlyPhoto="viewOnlyPhoto" />

            <EditPhotos
                v-show="selectedTab === 'PHOTO'"
                v-model="me.photos"
                @onChangeDeletePhotoIds="onChangeDeletePhotoIds"
            />

            <div v-show="selectedTab === 'PROFILE'">
                <EditIntro :childOf="'profile'" v-model="profile.intro" />
                <div class="hr" />
                <EditProfileItems v-model="profile" :sections="sections" :required="required" />
            </div>

            <EditKeywords v-show="selectedTab === 'INTEREST_TAG'" />
        </main>
    </div>
</template>

<script>
import EditPhotos from './components/EditPhotos'
import EditIntro from './components/EditIntro'
import EditProfileItems from './components/EditProfileItems'
import profileService from '@/services/profile'
import EditProfileTabs from './components/EditProfileTabs'
import EditKeywords from './components/EditKeywords'

export default {
    name: 'EditProfilePage',
    components: {
        EditPhotos,
        EditIntro,
        EditProfileItems,
        EditProfileTabs,
        EditKeywords,
    },
    data: () => ({
        profile: null,
        deletePhotoIds: [],
        selectedTab: '',
        showHeaderTitle: false,
    }),
    props: {
        initialTab: String,
        viewOnlyPhoto: Boolean,
    },
    watch: {
        selectedTab(newTab, oldTab) {
            if (oldTab) {
                const mainDom = this.$refs.main
                mainDom.scrollTop = 0
            }
        },
    },
    computed: {
        me() {
            return this.$store.getters.me
        },
        requiredKeys() {
            const required = [
                // 'nickname',
                'state',
                'jobCategory',
                'job',
                'school',
                'schoolType',
                'kind',
                'shape',
                'smoking',
                'religion',
            ]

            required.push(this.profile.state.is_station ? 'station' : 'region')
            if (this.profile.job_category.verification_required) required.push('company')
            if (this.profile.school_type.verification_required) required.push('university')

            return required
        },
        sections() {
            if (!this.profile) return

            const O = ['nickname']
            const A = ['state', this.profile.state.is_station ? 'station' : 'region']
            const B = ['job', 'jobCategory', 'company', 'jobType', 'jobDescription']
            const C = ['school', 'schoolType', 'university', 'major']
            const D = ['kind', 'mbti', 'shape', 'smoking', 'religion']
            const E = ['car', 'pet']

            if (this.me.id >= 182150 && this.me.id <= 183132) {
                return this.profile.school.is_student ? [O, A, C, D, E] : [O, A, B, C, D, E]
            } else {
                return this.profile.school.is_student ? [A, C, D, E] : [A, B, C, D, E]
            }
        },
    },
    methods: {
        async init() {
            this.selectedTab = this.initialTab || 'PHOTO'

            try {
                this.profile = await profileService.me()
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
        async saveProfile() {
            this.missingKeys = this.requiredKeys
                .filter(key => {
                    const fieldName = this.$case.toSnake(key)
                    return typeof this.profile[fieldName] !== 'boolean' && !this.profile[fieldName]
                })
                .map(key => this.$translate(this.$case.toConst(key)))

            if (this.missingKeys.length > 0) {
                this.$toast.error(`${this.missingKeys.join(', ')}를 입력하지 않았습니다`)
                return
            }

            try {
                this.$loading(true)
                await profileService.update(this.preparedPayload())
                // this.$store.dispatch('loadMe')
                // this.$store.dispatch('loadSettings')
                this.$unregisterBackHandler()
                this.$loading(false)
                this.$router.go(-1)
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
        required(key) {
            return this.requiredKeys.indexOf(key) !== -1
        },
        userInputAllowed(key) {
            return ['job', 'jobType', 'company', 'university', 'major'].indexOf(key) !== -1
        },
        onChangeDeletePhotoIds(deletePhotoIds) {
            this.deletePhotoIds = deletePhotoIds
        },
        preparedPayload() {
            const keys = this.sections.reduce((result, keys) => (result || []).concat(keys))
            const form = new FormData()
            form.append('id', this.profile.id)
            form.append('intro', this.profile.intro || '')
            keys.forEach(camel => {
                if (camel === 'smoking') {
                    form.append('smoking', this.profile.smoking ? '1' : '0')
                    return
                }

                if (['shape', 'religion', 'kind', 'mbti', 'pet'].indexOf(camel) > -1) {
                    return
                }

                if (camel === 'car') {
                    if (typeof this.profile.car === 'boolean') {
                        form.append(camel, this.profile.car)
                    } else if (typeof this.profile.car === 'string' && this.profile.car) {
                        form.append(camel, this.profile.car === '보유' ? '1' : '0')
                    }

                    return
                }

                if (camel === 'jobDescription' && this.profile.job_description) {
                    form.append('job_description', this.profile.job_description)
                    return
                }

                const snake = this.$case.toSnake(camel)
                if (!this.profile[snake]) return

                const prefix = this.userInputAllowed(camel) ? 'name' : 'id'
                form.append(`${snake}_${prefix}`, this.profile[snake][prefix])
            })

            const profileOptionIds = this.profile.profile_options.map(po => po.profile_option_id)
            form.append('profile_option_ids', JSON.stringify(profileOptionIds))

            this.me.photos.filter(p => p.blob).forEach((p, idx) => form.append(`photo${idx + 1}`, p.blob, p.fileName))
            form.append('delete_photo_ids', JSON.stringify(this.deletePhotoIds))

            return form
        },
        onScroll(event) {
            const scrollTop = event.target.scrollTop

            scrollTop >= 48 ? (this.showHeaderTitle = true) : (this.showHeaderTitle = false)
        },
        back() {
            // 나갈 때 최신 프로필로 상태 업데이트
            this.saveProfile().then(() => {
                this.$store.dispatch('loadMe')
                this.$store.dispatch('loadSettings')
            })
        },
    },
    mounted() {
        // this.viewOnlyPhoto = this.$route.params.viewOnly || false
        this.init()
        this.$registerBackHandler(this.back)
    },
    beforeDestroy() {
        this.$unregisterBackHandler(this.back)
    },
}
</script>

<style lang="scss" scoped>
$edit-profile-padding: 16px;
$header-height: 52px;

.edit-profile {
    .header-bar {
        padding: 8px 16px;
        height: $header-height;
        display: flex;
        align-items: center;
        justify-content: center;

        & > * {
            margin-right: auto;
        }
        .material-icons {
            color: $grey-08;
        }

        .header-title {
            font-size: 16px;
            color: black;
            font-weight: 500;

            @include f-medium;
        }
    }

    .main {
        overflow-y: scroll;
        height: calc(100vh - #{$header-height});
    }

    .edit-profile-tabs {
        position: sticky;
        top: 0;
        background-color: white;
    }

    .page-header {
        margin-bottom: 16px;

        .title {
            line-height: normal;

            @include f-medium;
        }
    }

    .hr {
        margin: 32px 0;
        height: 1px;
        background: $grey-02;
    }

    .edit-intro {
        margin-top: 32px;
    }
}
</style>
