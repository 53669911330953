<template>
    <div class="edit-profile-items">
        <h3 class="title" v-html="$translate('BASIC_INFORMATION')" />
        <div :key="idx" v-for="(keys, idx) in sections" class="section">
            <div class="profile-item" :key="key" v-for="key in keys">
                <template v-if="key === 'jobDescription'">
                    <div class="job-description">
                        <div class="desc">하는 일에 대해 구체적으로 이야기해주세요!</div>
                        <div class="textarea-wrapper">
                            <textarea
                                class="textarea"
                                rows="5"
                                placeholder="일에 대한 보람, 열정, 자부심 등"
                                :value="profile.job_description"
                                @input="onInputJobDescription"
                            />
                            <i
                                v-show="(profile.job_description || []).length > 0"
                                class="material-icons"
                                @click="deleteJobDescription"
                            >
                                cancel
                            </i>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="label" :class="{ required: required(key) }" v-html="$translate($case.toConst(key))" />
                    <div :class="{ 'flex-row': hasChangedNickname && key === 'nickname' }">
                        <div
                            @click="onClickProfileItem(key)"
                            class="btn-selector"
                            :class="{
                                'c-primary': getVal(key) !== 'NO_VALUE',
                                'no-input': getVal(key) === 'NO_VALUE',
                                'changed-nickname': hasChangedNickname && key === 'nickname',
                            }"
                            v-html="$translate(getVal(key))"
                        ></div>
                        <i v-if="hasChangedNickname && key === 'nickname'" class="material-icons lock">lock</i>
                        <div
                            v-if="['station', 'region'].indexOf(key) !== -1"
                            class="sub-region-desc"
                            v-html="subRegion"
                        />
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EditProfileItems',
    props: ['value', 'sections', 'required'],
    data: () => ({
        profile: {},
        profileChanged: false,
    }),
    mounted() {
        this.init()
    },
    beforeDestroy() {
        if (this.profileChanged) this.$toast.success('SAVED')
    },
    computed: {
        subRegion() {
            if ((this.profile.state || {}).is_station) {
                return this.profile.station
                    ? this.$translate('SUB_REGION_DESC').replace(
                          /%s/,
                          this.profile.station.etc || `${this.profile.station.region} ${this.profile.station.district}`,
                      )
                    : ''
            }

            return this.profile.region_name
                ? this.$translate('SUB_REGION_DESC').replace(
                      /%s/,
                      `${this.profile.region.name} ${this.profile.region.district}`,
                  )
                : ''
        },
        hasChangedNickname() {
            return (this.profile || {}).is_changed_nickname
        },
    },
    methods: {
        init() {
            this.profile = this.value || {}
        },
        getVal(key) {
            if (key === 'smoking') return this.profile.smoking ? 'SMOKING' : 'NON_SMOKING'

            if (['nickname', 'kind', 'shape', 'mbti', 'pet', 'religion'].indexOf(key) >= 0) {
                if (!this.profile[key]) return 'NO_VALUE'

                return this.profile[key]
            }

            if (key === 'car') {
                if (typeof this.profile.car === 'boolean') {
                    return this.profile.car ? '보유' : '미보유'
                }

                if (!this.profile.car) return 'NO_VALUE'

                return this.profile.car
            }

            const val = this.profile[this.$case.toSnake(key)]
            if (!val) return 'NO_VALUE'

            return key === 'region' ? val.district : val.name
        },
        onClickProfileItem(key) {
            if (key === 'nickname') {
                if (this.hasChangedNickname !== true) {
                    this.$modal
                        .custom({
                            component: 'ModalNickname',
                        })
                        .then(nickname => {
                            if (nickname != null) {
                                this.profile.nickname = nickname
                                this.profile.is_changed_nickname = true
                            }
                        })
                }
            } else {
                this.$modal
                    .custom({
                        component: 'ModalProfileItemSelector',
                        options: {
                            model: key,
                            profile: this.profile,
                        },
                    })
                    .then(item => {
                        if (!item || item.length === 0) {
                            return
                        } else {
                            this.profileChanged = true
                        }

                        if (key === 'state' && this.profile.state.name !== item.name) {
                            this.profile.station = null
                            this.profile.region = null
                        }

                        if (key === 'smoking') {
                            this.profile.smoking = item.name === 'SMOKING'
                            return
                        }

                        if (key === 'kind') {
                            this.$set(this.profile, key, item.map(i => i.name).join(', '))
                            this.updateProfileOptions(key, item)
                            return
                        }

                        if (['shape', 'mbti', 'religion'].indexOf(key) > -1) {
                            this.$set(this.profile, key, item.name)
                            this.updateProfileOptions(key, item)
                            return
                        }

                        if (key === 'car') {
                            this.$set(this.profile, key, item.name)
                            return
                        }

                        if (key === 'pet' && item.length > 0) {
                            this.$set(this.profile, key, item.map(i => i.name).join(', '))
                            this.updateProfileOptions(key, item)
                            return
                        }

                        this.$set(this.profile, this.$case.toSnake(key), item)
                        this.$emit('input', this.profile)
                        this.$forceUpdate()
                    })
            }
        },
        onInputJobDescription(e) {
            this.$set(this.profile, 'job_description', e.target.value)
            this.profileChanged = true
        },
        deleteJobDescription() {
            this.$set(this.profile, 'job_description', null)
            this.profileChanged = true
        },
        updateProfileOptions(key, item) {
            const currentProfileOptions = this.profile.profile_options
            if (key === 'kind' || key === 'pet') {
                for (let i = currentProfileOptions.length - 1; i >= 0; i -= 1) {
                    if (currentProfileOptions[i].ptype === key) {
                        currentProfileOptions.splice(i, 1)
                    }
                }

                item.forEach(i => {
                    currentProfileOptions.push({ profile_option_id: i.id, ptype: key })
                })

                this.$set(this.profile, 'profile_options', currentProfileOptions)
            } else {
                const index = currentProfileOptions.findIndex(po => po.ptype === key)

                if (index < 0) {
                    currentProfileOptions.push({ profile_option_id: item.id, ptype: key, name: item.name })
                } else {
                    currentProfileOptions[index].profile_option_id = item.id
                }

                this.$set(this.profile, 'profile_options', currentProfileOptions)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.edit-profile-items {
    .title {
        margin-left: 16px;
        margin-bottom: 24px;
        color: black;
        font-size: 18px;
        font-weight: 500;

        @include f-medium;
    }

    .section {
        padding: 0 20px 32px;

        &:first-child {
            padding-top: 0;
        }

        .profile-item {
            display: flex;

            .label {
                font-size: 16px;
                color: $grey-06;
                width: 128px;

                &.required::after {
                    content: '*';
                    color: $purple-primary;
                }
            }

            &:not(:last-child) {
                margin-bottom: 28px;
            }

            &:last-child {
                padding-bottom: 28px;
                border-bottom: solid 1px $grey-01;
            }
        }

        .job-description {
            width: 100%;

            .desc {
                margin-bottom: 8px;
                color: $grey-06;
                font-size: 12px;

                @include f-medium;
            }

            .textarea-wrapper {
                display: flex;

                &:focus-within {
                    border: solid 1px rgba(138, 116, 255, 0.5);
                }

                .textarea {
                    margin-right: 8px;
                    padding: 0;
                    font-size: 15px;
                    color: black;
                    line-height: 1.6;

                    @include f-regular;
                }

                .material-icons {
                    font-size: 16px;
                    color: $grey-06;
                }
            }
        }

        &:last-child {
            .profile-item {
                border: 0;
            }
        }
    }

    .sub-region-desc {
        margin-top: 4px;
        font-size: 12px;
        color: $grey-06;
    }

    .btn-selector {
        font-size: 16px;
        color: $purple-primary;

        @include f-medium;

        &.no-input {
            color: $pink-deep;
        }

        &.changed-nickname {
            color: $grey-08;
        }
    }

    .lock {
        font-size: 18px !important;
        color: $grey-02;
        margin-left: 8px;
        background-color: $grey-02;
        border-radius: 50%;
        padding: 3px;
    }

    .material-icons {
        font-size: $icon-size;
        color: $grey-05;
    }
}
</style>
